import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import getQueryKeys from "@core/services/nocd-api/clinician-dashboard/query-keys";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

interface DismissNotoClinicianFeedback {
  clinician_email: string;
}

const dismissNotoClinicianFeedback = (
  payload: DismissNotoClinicianFeedback,
  accessToken: string
) =>
  api
    .post<DismissNotoClinicianFeedback>(
      `/v1/clinicians/noto_feedback/dismiss`,
      payload,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch(transformAPIError);

export const useDismissNotoClinicianFeedback = (): UseMutationResult<
  DismissNotoClinicianFeedback,
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload: DismissNotoClinicianFeedback) => {
      return dismissNotoClinicianFeedback(payload, accessToken);
    },
    {
      onSuccess: (_, payload) => {
        return queryClient.invalidateQueries(
          getQueryKeys(accessToken).notoFeedbackForm(payload.clinician_email)
        );
      },
    }
  );
};
