import useAppointmentsThisWeek from "@core/services/nocd-api/clinician-dashboard/queries/useAppointmentsThisWeek";
import useAppointmentsToday from "@core/services/nocd-api/clinician-dashboard/queries/useAppointmentsToday";
import { AppointmentsOverview } from "@core/services/nocd-api/clinician-dashboard/types";
import {
  DocumentTextIcon as DocumentTextIconOutline,
  FolderOpenIcon as FolderOpenIconOutline,
} from "@heroicons/react/24/outline";
import {
  ArrowsRightLeftIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  FolderOpenIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRowClassNameParams,
} from "@mui/x-data-grid-pro";
import { formatInTimeZone } from "date-fns-tz";
import { isEmpty } from "lodash";
import { useState } from "react";

import OpenSpotlightButton from "./OpenSpotlightButton";
import SectionTitle from "./SectionTitle";

const NameCell = ({
  row,
}: {
  row: AppointmentsOverview["appointments"][0];
}) => {
  return (
    <Stack
      direction="row"
      flex="1"
      width="100%"
      alignItems="center"
      spacing={1}
    >
      <Avatar
        sx={{
          width: "24px",
          height: "24px",
        }}
        src={row.member_avatar_url}
        alt={row.member_name}
      />

      <OpenSpotlightButton
        userId={row.member_user_id}
        name={row.member_name}
        is_birthday={row.is_birthday}
      />
      {row.is_transfer ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            color: "#F5A623",
            backgroundColor: "#FEEBCC",
            borderRadius: "8px",
            padding: "6px 8px",
          }}
        >
          <ArrowsRightLeftIcon
            style={{ width: "16px", height: "16px", color: "inherit" }}
          />

          <Typography sx={{ fontWeight: 900, fontSize: "12px" }}>
            TRANSFER
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
};

const getColumns = (
  range: "today" | "this week"
): GridColDef<AppointmentsOverview["appointments"][0]>[] => [
  {
    headerName: "Signed",
    flex: 1,
    type: "boolean",
    field: "is_completed",
    maxWidth: 80,
    valueGetter: (params) => {
      return !!params.value;
    },
    editable: false,
    renderCell: (params) => {
      if (params.value) {
        return (
          <Tooltip title="Signed">
            <CheckCircleIcon
              style={{ width: "16px", height: "16px", color: "#22C55E" }}
            />
          </Tooltip>
        );
      }

      if (new Date(params.row.starts_at) < new Date()) {
        return (
          <Tooltip title="Not signed">
            <PlusCircleIcon
              style={{
                width: "16px",
                height: "16px",
                color: "#FF5D47",
                transform: "rotate(45deg)",
              }}
            />
          </Tooltip>
        );
      }
      return null;
    },
  },
  {
    headerName: "Member",
    flex: 1,
    minWidth: 200,
    type: "string",
    field: "member_name",
    editable: false,
    renderCell: (params) => {
      return <NameCell row={params.row} />;
    },
  },
  {
    headerName: "Time",
    flex: 1,
    type: "string",
    field: "starts_at",
    valueGetter: (params) => {
      const date = new Date(params.row.starts_at);

      if (range === "today") {
        return `${formatInTimeZone(
          date,
          params.row.clinician_tz || "America/Chicago",
          "h:mm aa zzz"
        )} (${params.row.duration_in_mins}m)`;
      }

      return `${formatInTimeZone(
        date,
        params.row.clinician_tz || "America/Chicago",
        "MMM dd, h:mm aa zzz"
      )} (${params.row.duration_in_mins}m)`;
    },
    editable: false,
  },
  {
    headerName: "Type",
    flex: 1,
    type: "string",
    field: "session_type",
    editable: false,
  },
  {
    headerName: "Forms",
    flex: 1,
    type: "string",
    field: "overdue_forms",
    editable: false,
    valueGetter: (params) => {
      return !isEmpty(params.row.overdue_forms);
    },
    renderCell: (params) => {
      if (params.value) {
        return (
          <Tooltip
            placement="top"
            title={`${params.row.overdue_forms.join(", ")} forms missing`}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <ExclamationTriangleIcon
                style={{ height: "16px", width: "16px", color: "#F5A623" }}
              />

              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                Missing
              </Typography>
            </Stack>
          </Tooltip>
        );
      }

      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <CheckCircleIcon
            style={{ height: "16px", width: "16px", color: "#4ADE80" }}
          />

          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            Up to date
          </Typography>
        </Stack>
      );
    },
  },
  {
    headerName: "Scheduled Sessions",
    flex: 1,
    type: "string",
    field: "member_scheduling_frequency",
    editable: false,
    renderCell: (params) => {
      if (!params.row.member_scheduling_frequency) {
        return params.row.scheduled_session_count;
      }

      return `${params.row.scheduled_session_count} (${params.row.member_scheduling_frequency})`;
    },
  },
  {
    headerName: "Actions",
    flex: 1,
    type: "string",
    field: "id",
    minWidth: 150,
    align: "right",
    headerAlign: "right",
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            sx={{
              "&:hover .view-chart-button-collapsed": {
                display: "none",
              },
              ".view-chart-button-expanded": {
                display: "none",
              },
              "&:hover .view-chart-button-expanded": {
                display: "flex",
              },
            }}
          >
            <IconButton
              component="a"
              href={`/members/${String(params.row.member_user_id)}`}
              target="_blank"
              rel="noreferrer"
              className="view-chart-button-collapsed"
              sx={{
                p: "12px",
                borderRadius: "50%",
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FolderOpenIconOutline
                style={{
                  width: "20px",
                  height: "20px",
                  color: "rgba(0,0,0,0.8)",
                }}
              />
            </IconButton>

            <Button
              component="a"
              href={`/members/${String(params.row.member_user_id)}`}
              target="_blank"
              rel="noreferrer"
              sx={{
                backgroundColor: "#E2E8F0",
                color: "#0F172A",
                fontWeight: 700,
                padding: 1,
                lineHeight: 1,
                "&:hover": {
                  backgroundColor: "#E2E8F0",
                },
                borderRadius: 999,
              }}
              className="view-chart-button-expanded"
              endIcon={
                <FolderOpenIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "rgba(0,0,0,0.8)",
                  }}
                />
              }
            >
              Chart
            </Button>
          </Box>

          <Box
            sx={{
              "&:hover .view-session-note-button-collapsed": {
                display: "none",
              },
              ".view-session-note-button-expanded": {
                display: "none",
              },
              "&:hover .view-session-note-button-expanded": {
                display: "flex",
              },
            }}
          >
            <IconButton
              component="a"
              href={`/appointments/${String(params.row.id)}`}
              target="_blank"
              rel="noreferrer"
              className="view-session-note-button-collapsed"
              sx={{
                p: "12px",
                borderRadius: "50%",
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DocumentTextIconOutline
                style={{
                  width: "20px",
                  height: "20px",
                  color: "rgba(0,0,0,0.8)",
                }}
              />
            </IconButton>

            <Button
              component="a"
              href={`/appointments/${String(params.row.id)}`}
              target="_blank"
              rel="noreferrer"
              sx={{
                backgroundColor: "#E2E8F0",
                color: "#0F172A",
                fontWeight: 700,
                padding: 1,
                lineHeight: 1,
                "&:hover": {
                  backgroundColor: "#E2E8F0",
                },
                borderRadius: 999,
              }}
              className="view-session-note-button-expanded"
              endIcon={
                <DocumentTextIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "rgba(0,0,0,0.8)",
                  }}
                />
              }
            >
              Note
            </Button>
          </Box>
        </Stack>
      );
    },
  },
];

const SessionTodayTable = () => {
  const { data, isLoading, error } = useAppointmentsToday();

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flex={1}
        alignItems="center"
        p={6}
      >
        <Typography color="error.main" variant="body2">
          Error loading today&apos;s sessions
        </Typography>
      </Box>
    );
  }

  return (
    <Box height="600px">
      <DataGridPro
        getRowClassName={(
          params: GridRowClassNameParams<
            AppointmentsOverview["appointments"][0]
          >
        ) => {
          const classes: string[] = [];

          const isInPast = new Date(params.row.starts_at) < new Date();

          if (!isInPast) {
            classes.push("bold");
          }

          if (!params.row.is_completed && isInPast) {
            classes.push("overdue");
          }
          return classes.join(" ");
        }}
        sx={{
          width: "100%",
          marginTop: "16px",
          ".bold": { fontWeight: 700 },
          ".overdue": { backgroundColor: "#FFF5F3" },
          fontSize: "14px",
        }}
        columns={getColumns("today")}
        rows={data?.appointments ?? []}
        autoPageSize
        pagination
        loading={isLoading}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

const SessionThisWeekTable = () => {
  const { data, isLoading, error } = useAppointmentsThisWeek();

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flex={1}
        alignItems="center"
        p={6}
      >
        <Typography color="error.main" variant="body2">
          Error loading this week&apos;s sessions
        </Typography>
      </Box>
    );
  }

  return (
    <Box height="600px">
      <DataGridPro
        getRowClassName={(
          params: GridRowClassNameParams<
            AppointmentsOverview["appointments"][0]
          >
        ) => {
          const classes: string[] = [];

          const isInPast = new Date(params.row.starts_at) < new Date();

          if (!isInPast) {
            classes.push("bold");
          }

          if (!params.row.is_completed && isInPast) {
            classes.push("overdue");
          }
          return classes.join(" ");
        }}
        sx={{
          width: "100%",
          marginTop: "16px",
          ".bold": { fontWeight: 700 },
          ".overdue": { backgroundColor: "#FFF5F3" },
          fontSize: "14px",
        }}
        columns={getColumns("this week")}
        rows={data?.appointments ?? []}
        autoPageSize
        pagination
        loading={isLoading}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

const Sessions = (boxProps: BoxProps) => {
  const [sessionRange, setSessionRange] = useState<"today" | "this week">(
    "today"
  );

  return (
    <Box
      sx={{
        p: "24px",
        mt: 2,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        ...(boxProps?.sx ?? {}),
      }}
      {...(boxProps ?? {})}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <SectionTitle title="Sessions" />

        <Stack direction="row" sx={{ alignItems: "center" }}>
          <style jsx>{`
            button {
              border: none;
              display: inline-block;
              border-radius: 8px;
              color: #2e8eff;
              background-color: white;
              transition: all 0.2s ease-in-out;
            }

            .button-active {
              color: white;
              background-color: #2e8eff;
            }

            button:hover {
              cursor: pointer;
              background-color: #f1f5f9;
              color: #0f172a;
            }
          `}</style>

          <button
            type="button"
            onClick={() => {
              setSessionRange("today");
            }}
            className={sessionRange === "today" ? "button-active" : ""}
          >
            <Typography
              sx={{ fontWeight: 700, padding: "4px 8px", fontSize: "12px" }}
            >
              TODAY
            </Typography>
          </button>

          <button
            type="button"
            onClick={() => {
              setSessionRange("this week");
            }}
            className={sessionRange === "this week" ? "button-active" : ""}
          >
            <Typography
              sx={{
                fontWeight: 700,
                padding: "4px 8px",
                fontSize: "12px",
              }}
            >
              WEEK
            </Typography>
          </button>

          <Box sx={{ paddingLeft: "20px" }}>
            <MuiLink
              fontWeight={700}
              color="nocdBlue.main"
              sx={{
                "&:hover": {
                  color: "nocdBlue.dark",
                },
              }}
              underline="none"
              href="/appointments"
              target="_blank"
            >
              See all
            </MuiLink>
          </Box>
        </Stack>
      </Box>

      {sessionRange === "today" ? (
        <SessionTodayTable />
      ) : (
        <SessionThisWeekTable />
      )}
    </Box>
  );
};

export default Sessions;
