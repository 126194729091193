import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

export interface ThoughtOfTheDay {
  text: string;
  assigned_date: string;
  created_by: string;
  created_at: string;
}

const useThoughtOfTheDay = (
  options?: UseQueryOptions<ThoughtOfTheDay[], Error>
): UseQueryResult<ThoughtOfTheDay[], Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    ["thought-of-the-day", accessToken] as const,
    async ({ signal }) =>
      api
        .get<ThoughtOfTheDay[]>("/v1/clinicians/thought_of_the_day", {
          headers: {
            Authorization: accessToken,
          },
          signal,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!accessToken,
    }
  );
};

export default useThoughtOfTheDay;
