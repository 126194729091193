/* eslint-disable no-nested-ternary */
import { EhrRoles } from "@core/constants";
import { useUser } from "@core/services/nocd-api";
import { MemberChartPageTabs } from "@core/types";
import { CheckboxWithLabel } from "@core/ui";
import DescriptionList from "@core/ui/DescriptionList";
import SectionTitle from "@features/clinician-dashboard/components/SectionTitle";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { InfoOutlined, Refresh } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
// import FlagIcon from "@mui/icons-material/Flag";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link as MuiLink,
  List,
  ListItemButton,
  ListSubheader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";

import useMemberFinder from "../hooks/useMemberFinder";
import useOfferSlotToMember from "../hooks/useOfferSlotToMember";
import { Candidate, MemberFinderResponse } from "../types";
import { formatSchedulingPreferences } from "../utils";

const getDisplayName = (candidate: Candidate): string => {
  if (candidate.type === "current_member") {
    return `${candidate.first_name} ${candidate.last_name}`;
  }

  return candidate.user_id.toString();
};

const RequestSentCard = ({
  candidate,
}: {
  candidate: MemberFinderResponse["already_offered"][string][0];
}) => {
  return (
    <Box
      sx={{
        paddingY: "24px",
        paddingX: "12px",
        border: "1px solid #49DE80",
        borderRadius: "8px",
        backgroundColor: "#EFFDF4",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="bold">{candidate.label}</Typography>

        <Box
          sx={{
            backgroundColor: "white",
            color: "#49DE80",
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "uppercase",
            py: 0.5,
            px: 1,
            borderRadius: "4px",
          }}
        >
          Request sent
        </Box>
      </Stack>
    </Box>
  );
};

interface MemberFinderInnerProps extends BoxProps {
  clinicianEmail: string;
  view: "clinician" | "debug";
}

export const MemberFinderInner = ({
  clinicianEmail,
  view = "clinician",
  ...boxProps
}: MemberFinderInnerProps) => {
  const [startedSearch, setStartedSearch] = useState(false);

  const [candidateToView, setCandidateToView] = useState<Candidate | null>(
    null
  );

  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDurationInMins, setSelectedDurationInMins] =
    useState<number>(60);
  const [skipMaxFirstSessionCheck, setSkipMaxFirstSessionCheck] =
    useState(false);
  const { data, isLoading, error, refetch, isFetching, isIdle } =
    useMemberFinder(
      clinicianEmail,
      useMemo(
        () => ({
          // This is an expensive query, so we want it to be disabled by default.
          // If the clinician wants to use it, they can enable it manually.
          //
          // However, once they've already manually enabled it, we don't
          // want them to have to do it again. We use a piece of state
          // to track if they started the search.
          enabled: startedSearch,
          durationInMins: selectedDurationInMins,
          skipMaxFirstSessionCheck,
        }),
        [selectedDurationInMins, skipMaxFirstSessionCheck, startedSearch]
      )
    );
  const { mutateAsync: offerSlot, isLoading: isOffering } =
    useOfferSlotToMember();

  const memberListRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      height={600}
      position="relative"
      sx={{
        paddingY: "24px",
        paddingX: "12px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "100%",
        backgroundColor: "white",
        ...(boxProps?.sx ?? {}),
      }}
      {...(boxProps ?? {})}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        paddingX={1}
        mb={3}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <CalendarDaysIcon width={32} height={32} />

          <Stack direction="row" alignItems="center">
            <SectionTitle title="Member finder" />

            <IconButton
              size="small"
              color="default"
              href="https://docs.google.com/document/d/1HMl0sIo2ykjzzQx38imwHvxBKUNygUjCv6w8Om9U3Vc"
              target="_blank"
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          {view === "debug" ? (
            <CheckboxWithLabel
              name="skip_first_session_check"
              label="Skip max First Session check"
              checked={skipMaxFirstSessionCheck}
              onChange={(e) => setSkipMaxFirstSessionCheck(e.target.checked)}
            />
          ) : null}

          <ToggleButtonGroup
            size="small"
            color="primary"
            value={String(selectedDurationInMins)}
            exclusive
            onChange={(_e, newValue) => {
              setSelectedDurationInMins(Number(newValue));

              // Reset state
              setCandidateToView(null);
              setSelectedDate(null);
            }}
            aria-label="Duration in mins"
          >
            <ToggleButton value="60">60 min</ToggleButton>
            <ToggleButton value="30">30 min</ToggleButton>
          </ToggleButtonGroup>

          <IconButton
            color="primary"
            onClick={() =>
              toast.promise(refetch(), {
                loading: "Refreshing slots and candidates...",
                success: "Refreshed!",
                error: (err: Error) =>
                  `Failed to refresh: ${err?.message ?? "Unknown error"}`,
              })
            }
            disabled={isFetching}
          >
            <Refresh />
          </IconButton>
        </Stack>
      </Stack>

      {isLoading ? (
        <Box
          height={400}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box
          height={400}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Typography color="error">{error?.message}</Typography>
        </Box>
      ) : // This will be the initial state.
      isIdle ? (
        <Box
          height={400}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={3}
        >
          <Button
            disabled={isFetching}
            variant="outlined"
            onClick={() => {
              setStartedSearch(true);
              return refetch();
            }}
          >
            Search for members
          </Button>
        </Box>
      ) : isEmpty(data.slots) ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={3}>
          <Typography>Nothing to show yet</Typography>
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <List
              sx={{
                maxHeight: 400,
                overflow: "auto",
              }}
              subheader={
                <ListSubheader
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "#AAAEB5",
                    lineHeight: 1.5,
                  }}
                  disableSticky
                >
                  Time
                </ListSubheader>
              }
            >
              {Object.keys(data.slots).map((date) => (
                <ListItemButton
                  selected={selectedDate === date}
                  key={date}
                  onClick={() => {
                    setSelectedDate(date);
                    memberListRef?.current?.scrollTo({
                      top: 0,
                    });
                  }}
                >
                  <span>
                    <Typography
                      fontSize="14px"
                      display="block"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "#AAAEB5",
                      }}
                    >
                      {formatInTimeZone(
                        new Date(date),
                        data.clinician_timezone,
                        "EEEE, MMM d"
                      )}
                    </Typography>

                    <Typography display="block">
                      {formatInTimeZone(
                        new Date(date),
                        data.clinician_timezone,
                        "h:mm aa"
                      )}
                    </Typography>
                  </span>
                </ListItemButton>
              ))}
            </List>
          </Grid>

          <Grid item xs={9}>
            <Stack
              ref={memberListRef}
              spacing={1}
              maxHeight={400}
              overflow="auto"
            >
              <Typography
                fontSize="14px"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: "#AAAEB5",
                }}
              >
                Member
              </Typography>

              {Array.isArray(data?.already_offered?.[selectedDate]) &&
              data?.already_offered?.[selectedDate]?.length > 0
                ? data?.already_offered?.[selectedDate].map((candidate) => (
                    <RequestSentCard
                      key={`${candidate.first_name}-${candidate.last_name}-${candidate.slot}-${candidate.created_at}`}
                      candidate={candidate}
                    />
                  ))
                : null}

              {!selectedDate ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Typography>Choose a slot to see candidates</Typography>
                </Box>
              ) : isEmpty(data?.slots?.[selectedDate]?.candidates) ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Typography>No candidates to show yet</Typography>
                </Box>
              ) : (
                data.slots[selectedDate].candidates.map((candidate) => (
                  <Box
                    key={`${candidate.user_id}-${candidate.type}`}
                    sx={{
                      paddingY: "24px",
                      paddingX: "12px",
                      border: "1px solid #CBD5E1",
                      borderRadius: "8px",
                      "&:hover": {
                        border: "1px solid #6E76EE",
                        backgroundColor: "#F2F8FF",
                      },
                      "&:hover .protocol-type-chip": {
                        display: "none",
                      },
                      "&:focus-within .protocol-type-chip": {
                        display: "none",
                      },
                      ".send-request-button": {
                        display: "none",
                      },

                      "&:hover .send-request-button": {
                        display: "block",
                      },
                      "&:focus-within .send-request-button": {
                        display: "block",
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width={200}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography
                            fontSize="14px"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "#AAAEB5",
                            }}
                            noWrap
                          >
                            {candidate.label}
                          </Typography>

                          {candidate.type !== "current_member" ? (
                            <IconButton
                              size="small"
                              onClick={() => setCandidateToView(candidate)}
                            >
                              <InfoOutlined fontSize="inherit" />
                            </IconButton>
                          ) : null}
                        </Stack>

                        <Typography>{getDisplayName(candidate)}</Typography>

                        {candidate.type === "current_member" ? (
                          <MuiLink
                            fontSize={14}
                            href={`/members/${candidate.user_id}?tab=${MemberChartPageTabs.SESSIONS}`}
                            target="_blank"
                          >
                            {candidate.user_id}
                          </MuiLink>
                        ) : null}
                      </Box>

                      <Box>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography
                            fontSize="14px"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "#AAAEB5",
                            }}
                          >
                            Last session
                          </Typography>
                        </Stack>

                        <Typography>
                          {candidate.last_session_date
                            ? `${formatInTimeZone(
                                new Date(candidate.last_session_date),
                                data.clinician_timezone,
                                "MMM d"
                              )}${
                                candidate.last_session_duration_in_mins != null
                                  ? ` (${candidate.last_session_duration_in_mins}m)`
                                  : ""
                              }`
                            : "None"}
                        </Typography>
                      </Box>

                      <Box>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography
                            fontSize="14px"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              color: "#AAAEB5",
                            }}
                          >
                            {candidate.type === "current_member"
                              ? `Next sessions (${
                                  candidate.scheduled_session_count || 0
                                })`
                              : "Next session"}
                          </Typography>
                        </Stack>

                        {candidate.type === "current_member" ? (
                          <Typography>
                            {Array.isArray(candidate.next_sessions) &&
                            candidate.next_sessions.length > 0
                              ? candidate.next_sessions
                                  .map((session) =>
                                    formatInTimeZone(
                                      new Date(session),
                                      data.clinician_timezone,
                                      "MMM d"
                                    )
                                  )
                                  .join(", ")
                              : "None"}
                          </Typography>
                        ) : (
                          <Typography>
                            {candidate.next_session_date
                              ? formatInTimeZone(
                                  new Date(candidate.next_session_date),
                                  data.clinician_timezone,
                                  "MMM d"
                                )
                              : "None"}
                          </Typography>
                        )}
                      </Box>

                      <Box
                        minWidth={105}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Box
                          sx={{
                            backgroundColor: "#E2E8F1",
                            color: "#0E1729",
                            fontSize: "12px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            py: 0.5,
                            px: 1,
                            borderRadius: "4px",
                          }}
                          className="protocol-type-chip"
                        >
                          {["Child", "Adolescent"].includes(
                            candidate.protocol_type
                          )
                            ? "C&A"
                            : "Adult"}
                        </Box>

                        <Button
                          className="send-request-button"
                          onClick={() =>
                            toast.promise(
                              offerSlot({
                                clinician_email: clinicianEmail,
                                user_id: candidate.user_id,
                                slot: selectedDate,
                                duration_in_mins: selectedDurationInMins,
                                type: candidate.type,
                              }),
                              {
                                loading:
                                  "Sending request and refreshing the list of available slots...",
                                success: "Request sent!",
                                error: (err: Error) =>
                                  `Failed to send request: ${
                                    err?.message ?? "Unknown error"
                                  }`,
                              }
                            )
                          }
                          disabled={isOffering}
                          sx={{
                            backgroundColor: "#6E76EE",
                            color: "white",
                            padding: 1,
                            lineHeight: 1,
                            "&:hover": {
                              backgroundColor: "#4d56e8",
                            },
                            textTransform: "none",
                            borderRadius: "4px",
                          }}
                        >
                          {candidate.type === "current_member"
                            ? "Send link"
                            : "Send request"}
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                ))
              )}
            </Stack>
          </Grid>
        </Grid>
      )}

      {candidateToView ? (
        <Dialog
          open
          onClose={() => setCandidateToView(null)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Member details</DialogTitle>
          <DialogContent>
            <DescriptionList dense>
              <DescriptionList.Header title={getDisplayName(candidateToView)} />
              <DescriptionList.Item label="Age" value={candidateToView.age} />
              <DescriptionList.Item
                label="Protocol type"
                value={candidateToView.protocol_type}
              />
              <DescriptionList.Item
                label="State"
                value={candidateToView.state}
              />
              <DescriptionList.Item
                label="Country"
                value={candidateToView.country_code}
              />
              <DescriptionList.Item
                label="Insurance"
                value={candidateToView.insurance}
              />
              <DescriptionList.Item
                label="Completed sessions"
                value={candidateToView.completed_session_count}
              />
              <DescriptionList.Item
                label="Last session"
                value={
                  candidateToView.last_session_date
                    ? formatInTimeZone(
                        new Date(candidateToView.last_session_date),
                        data.clinician_timezone,
                        "MMM d, yyyy"
                      )
                    : undefined
                }
              />
              <DescriptionList.Item
                label="Next session"
                value={
                  candidateToView.next_session_date
                    ? formatInTimeZone(
                        new Date(candidateToView.next_session_date),
                        data.clinician_timezone,
                        "MMM d, yyyy"
                      )
                    : undefined
                }
              />
              <DescriptionList.Item
                label="Scheduling pref."
                preserveWhiteSpace
                value={formatSchedulingPreferences(
                  candidateToView.scheduling_preferences,
                  candidateToView.diagnostic_timezone
                )}
              />
              <DescriptionList.Item
                label="Gender pref."
                value={candidateToView.therapist_preference_gender}
              />
            </DescriptionList>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setCandidateToView(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Box>
  );
};

export default function MemberFinder(boxProps: BoxProps) {
  const { data: user } = useUser();

  const isClinician = Boolean(user?.roles?.includes(EhrRoles.CLINICIAN));

  if (!user || !isClinician) {
    return null;
  }

  return (
    <MemberFinderInner
      clinicianEmail={user.email}
      {...(boxProps ?? {})}
      view="clinician"
    />
  );
}
