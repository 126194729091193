import { Typography } from "@mui/material";

export default function SectionTitle({ title }: { title: string }) {
  return (
    <Typography
      sx={{
        fontSize: "1.125rem",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
  );
}
