import { useCallback, useEffect, useRef, useState } from "react";

function usePrompt<T>() {
  const promise = useRef<null | ((value?: T) => void)>(null);
  const [open, setOpen] = useState<boolean>(false);

  // sets open to true and returns a promise that resolves when onConfirm
  // is called or the modal is closed by some other means
  const prompt = useCallback((): Promise<T> => {
    setOpen(true);
    return new Promise((resolve) => {
      // We need to defer setting the resolve callback on the ref. If we set it
      // right away, the set state will trigger a re-render, calling useEffect
      // and immediately, which would immediately dismiss the prompt.
      //
      // We are essentially doing:
      //
      // setOpen(true) -> rerender -> useEffect runs -> ref is null -> set ref = resolve
      setTimeout(() => {
        promise.current = resolve;
      }, 1);
    });
  }, [setOpen]);

  const onConfirm = useCallback(
    (value?: T) => {
      // if the promise ref has been set (such as being opened by prompt) call
      // the promise's resolver with the value and close the modal
      if (promise.current) {
        promise.current(value);
        promise.current = null;
      }
      setOpen(false);
    },
    [setOpen]
  );

  // Optional update function to programmatically resolve the promise with a value
  const update = useCallback(
    (value: T) => {
      onConfirm(value);
    },
    [onConfirm]
  );

  useEffect(() => {
    // if the promise's resolver exists, the modal was closed by some method
    // other than CONFIRMING the action, so resolve the promise with null to
    // indicate that the user didn't confirm the action
    if (promise.current) {
      promise.current(null);
      promise.current = null;
    }
  }, [open]);

  // Returning the prompt, isOpen, onChange, onConfirm, and update functions
  return { prompt, isOpen: open, onChange: setOpen, onConfirm, update };
}

export default usePrompt;
