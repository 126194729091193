import useMessagesOverview from "@core/services/nocd-api/clinician-dashboard/queries/useMessagesOverview";
import { MessagesOverview } from "@core/services/nocd-api/clinician-dashboard/types";
import { ArrowRightAlt } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Link as MuiLink,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import ZeroStateImage from "public/images/home-screen/messages-zero-state.svg";
import { FC } from "react";

import { humanizeTime } from "../utils";
import ItemCountBadge from "./ItemCountBadge";
import OpenSpotlightButton from "./OpenSpotlightButton";
import SectionTitle from "./SectionTitle";

interface MessageProps {
  message: MessagesOverview["unread_messages"][0];
}

const Message: FC<MessageProps> = ({ message }) => {
  return (
    <ListItem
      key={message.id}
      sx={{
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "#F1F5F9",
        },
        ".message-item-view-button": {
          display: "none",
        },
        "&:hover .message-item-timestamp": {
          display: "none",
        },
        "&:hover .message-item-view-button": {
          display: "flex",
        },
        "&:focus-within .message-item-timestamp": {
          display: "none",
        },
        "&:focus-within .message-item-view-button": {
          display: "flex",
        },
        display: "flex",
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            width: 36,
            height: 36,
          }}
          src={message.sender_avatar_url}
          alt={message.sender_name}
        />
      </ListItemAvatar>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <ListItemText
          primaryTypographyProps={{
            sx: {
              fontWeight: 700,
            },
          }}
          primary={
            <OpenSpotlightButton
              name={message.sender_name}
              userId={message.sender_user_id}
              is_birthday={message.is_birthday}
            />
          }
          secondary={message.content.slice(0, 20)}
        />

        <Typography variant="body2" className="message-item-timestamp">
          {humanizeTime(message.created_at)}
        </Typography>

        <Box
          className="message-item-view-button"
          sx={{
            ".message-item-view-button-expanded": {
              display: "none",
            },
            ".message-item-view-button-collapsed": {
              display: "flex",
            },
            "&:hover .message-item-view-button-expanded": {
              display: "flex",
            },
            "&:hover .message-item-view-button-collapsed": {
              display: "none",
            },
          }}
        >
          <IconButton
            sx={{
              color: "#0F172A",
              backgroundColor: "#FFF",
            }}
            onClick={() => {
              window.open(
                `/messaging?redirectId=${message.chat_channel_id}`,
                "_blank"
              );
            }}
            className="message-item-view-button-collapsed"
          >
            <ArrowRightAlt />
          </IconButton>

          <Button
            className="message-item-view-button-expanded"
            onClick={() => {
              window.open(
                `/messaging?redirectId=${message.chat_channel_id}`,
                "_blank"
              );
            }}
            sx={{
              backgroundColor: "#E2E8F0",
              color: "#0F172A",
              padding: 1,
              lineHeight: 1,
              "&:hover": {
                backgroundColor: "#E2E8F0",
              },
              borderRadius: 999,
            }}
            endIcon={<ArrowRightAlt />}
          >
            View
          </Button>
        </Box>
      </Stack>
    </ListItem>
  );
};

const ZeroState: FC = () => (
  <Stack sx={{ alignItems: "center", textAlign: "center" }}>
    <Box>
      <Image
        width={184}
        height={184}
        src={ZeroStateImage}
        alt="No new messages"
      />
    </Box>

    <Stack spacing={1} sx={{ color: "rgba(0,0,0,0.8)" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
        Those hands earned a break
      </Typography>

      <Typography sx={{ fontSize: "16px", opacity: ".8" }}>
        Hooray for inbox zero!
      </Typography>
    </Stack>
  </Stack>
);

const NewMessages: FC = () => {
  const { data, isLoading, error } = useMessagesOverview();

  return (
    <Box
      sx={{
        paddingY: "24px",
        paddingX: "12px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          flex: "1",
          paddingX: "8px",
          justifyContent: "space-between",
        }}
      >
        <SectionTitle title="New messages" />

        {isLoading ? (
          <Skeleton width="80px" height="24px" />
        ) : (
          <Stack direction="row" spacing={1}>
            <MuiLink
              fontWeight={700}
              color="nocdBlue.main"
              sx={{
                "&:hover": {
                  color: "nocdBlue.dark",
                },
              }}
              underline="none"
              href="/messaging"
              target="_blank"
            >
              See all
            </MuiLink>

            <ItemCountBadge count={data?.total_unread_count ?? 0} color="red" />
          </Stack>
        )}
      </Stack>

      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <List>
            <Skeleton width="100%" height="72px" />
            <Skeleton width="100%" height="72px" />
            <Skeleton width="100%" height="72px" />
            <Skeleton width="100%" height="72px" />
          </List>
        ) : // eslint-disable-next-line no-nested-ternary
        error ? (
          <Box
            display="flex"
            justifyContent="center"
            flex={1}
            alignItems="center"
            p={6}
          >
            <Typography color="error.main" variant="body2">
              Error loading messages
            </Typography>
          </Box>
        ) : data.unread_messages.length === 0 ? (
          <ZeroState />
        ) : (
          <List>
            {data?.unread_messages?.map((message) => (
              <Message key={message.id} message={message} />
            ))}
          </List>
        )
      }
    </Box>
  );
};

export default NewMessages;
