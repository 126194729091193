import { LightBulbIcon } from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Box, Chip, IconButton, Skeleton, Typography } from "@mui/material";
import { useState } from "react";

import useThoughtOfTheDay from "../hooks/useThoughtOfTheDay";

export default function ThoughtOfTheDay() {
  const { data: thoughtsOfTheDay, isLoading, error } = useThoughtOfTheDay();
  const [selectedThought, setSelectedThought] = useState<number>(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        p: 3,
        background:
          "linear-gradient(111deg, rgba(250, 211, 146, 0.8) -15.78%, rgba(218, 192, 254, 0.8) 39.98%, rgba(170, 174, 245, 0.8) 80.36%, rgba(84, 92, 234, 0.8) 109.2%)",
        minHeight: 200,
      }}
    >
      {error && !isLoading ? (
        <Typography variant="body1" color="error">
          {error?.message}
        </Typography>
      ) : null}

      <Box>
        <Box id="title" display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={0.5} mb={1}>
            <LightBulbIcon style={{ height: 24, width: 24 }} />

            <Typography
              variant="body1"
              fontWeight="bold"
              display="inline-block"
            >
              Thought of the Day
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mt={-1}>
            <IconButton
              onClick={() => {
                if (selectedThought === 0) {
                  setSelectedThought(thoughtsOfTheDay.length - 1);
                } else {
                  setSelectedThought(selectedThought - 1);
                }
              }}
            >
              <ChevronLeftIcon style={{ height: 24, width: 24 }} />
            </IconButton>
            <IconButton
              onClick={() => {
                if (selectedThought === thoughtsOfTheDay.length - 1) {
                  setSelectedThought(0);
                } else {
                  setSelectedThought(selectedThought + 1);
                }
              }}
            >
              <ChevronRightIcon style={{ height: 24, width: 24 }} />
            </IconButton>
          </Box>
        </Box>

        <Box
          id="thought"
          sx={{
            background: "rgba(255, 255, 255, 0.4)",
            p: 1.5,
            mt: 1,
            position: "relative",
          }}
          display="flex"
          alignItems="center"
          borderRadius="8px"
        >
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
          ) : (
            <>
              {new Date().getUTCDate() ===
              new Date(
                thoughtsOfTheDay[selectedThought]?.assigned_date
              ).getUTCDate() ? (
                <Chip
                  label="New"
                  color="primary"
                  size="small"
                  sx={{ position: "absolute", top: -5, left: -5 }}
                />
              ) : null}

              <Box
                dangerouslySetInnerHTML={{
                  __html: thoughtsOfTheDay[selectedThought].text,
                }}
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
