import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { AppointmentsOverview } from "../types";

const getAppointmentsToday = (
  accessToken: string,
  signal: AbortSignal,
  clinicianEmail?: string
) =>
  api
    .get<AppointmentsOverview>("/v1/clinicians/dashboard/appointments", {
      params: {
        time_range: "today",
        email: clinicianEmail,
      },
      signal,
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useAppointmentsToday(
  clinicianEmail?: string
): UseQueryResult<AppointmentsOverview, Error> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).appointmentsToday(clinicianEmail),
    ({ signal }) => getAppointmentsToday(accessToken, signal, clinicianEmail),
    {
      enabled: !!accessToken,
    }
  );
}
