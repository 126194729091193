import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import getQueryKeys from "@core/services/nocd-api/clinician-dashboard/query-keys";
import { useQuery } from "react-query";

interface ShowNotoFeedbackForm {
  isShowing: boolean;
}
const getNotoFeedbackForm = (
  accessToken: string | undefined,
  signal: AbortSignal,
  clinician_email: string
): Promise<ShowNotoFeedbackForm> =>
  api
    .get<ShowNotoFeedbackForm>(
      `/v1/clinicians/noto_feedback/${clinician_email}/show_feedback_form`,

      accessToken
        ? {
            signal,
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )

    .then(({ data }) => {
      return data;
    })
    .catch(transformAPIError);

const useNotoFeedbackForm = (clinician_email: string) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).notoFeedbackForm(clinician_email),
    ({ signal }) => getNotoFeedbackForm(accessToken, signal, clinician_email),
    {
      enabled: !!accessToken && !!clinician_email,
    }
  );
};
export default useNotoFeedbackForm;
