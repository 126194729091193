import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { MessagesOverview } from "../types";

const getMessagesOverview = (accessToken: string, signal: AbortSignal) =>
  api
    .get<MessagesOverview>("/v1/clinicians/dashboard/messages", {
      signal,
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useMessagesOverview(): UseQueryResult<
  MessagesOverview,
  Error
> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).messages(),
    ({ signal }) => getMessagesOverview(accessToken, signal),
    {
      enabled: !!accessToken,
    }
  );
}
