import useMyNotifications from "@core/services/nocd-api/notifications/queries/useMyNotifications";
import NotificationListItem from "@features/notifications/components/NotificationListItem";
import {
  Box,
  Link as MuiLink,
  List,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import ActivityPowerIcon from "public/images/home-screen/activity-icon.svg";
import ZeroStateImage from "public/images/home-screen/activity-zero-state.svg";
import { FC } from "react";

import SectionTitle from "./SectionTitle";

const ZeroState: FC = () => (
  <Stack sx={{ alignItems: "center", textAlign: "center" }}>
    <Box>
      <Image
        width={184}
        height={184}
        src={ZeroStateImage}
        alt="No Member Activity"
      />
    </Box>

    <Stack spacing={1} sx={{ color: "rgba(0,0,0,0.8)" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
        Enjoy the view
      </Typography>

      <Typography sx={{ fontSize: "16px", opacity: ".8" }}>
        Take a breather. You deserve it.
      </Typography>
    </Stack>
  </Stack>
);

const MemberActivity: FC = () => {
  const { data, isLoading, error } = useMyNotifications(4);

  const notifications = (data?.pages ?? []).flatMap(
    (page) => page.notifications
  );

  return (
    <Box
      sx={{
        height: "100%",
        paddingY: "24px",
        paddingX: "12px",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          flex: "1",
          paddingX: "8px",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          sx={{
            paddingX: "1px",
            justifyContent: "space-between",
          }}
        >
          <Image
            width={55}
            height={35}
            src={ActivityPowerIcon}
            alt="activity icon"
          />
          <SectionTitle title="Member activity" />
        </Stack>
        {isLoading ? (
          <Skeleton width="80px" height="24px" />
        ) : (
          <Stack direction="row" spacing={1}>
            <MuiLink
              fontWeight={700}
              color="nocdBlue.main"
              sx={{
                paddingX: "3px",
                "&:hover": {
                  color: "nocdBlue.dark",
                },
              }}
              underline="none"
              href="/my/notifications"
              target="_blank"
            >
              See all
            </MuiLink>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "nocdBlue.main",
                color: "nocdBlue.contrastText",
                fontSize: "12px",
                padding: "2px 8px",
                fontWeight: "700",
                borderRadius: "8px",
              }}
            >
              {data?.pages?.[0]?.total_count ?? 0}
            </Box> */}
          </Stack>
        )}
      </Stack>

      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <List>
            <NotificationListItem.Loading />
            <NotificationListItem.Loading />
            <NotificationListItem.Loading />
            <NotificationListItem.Loading />
          </List>
        ) : // eslint-disable-next-line no-nested-ternary
        error ? (
          <Box
            display="flex"
            justifyContent="center"
            flex={1}
            alignItems="center"
            p={6}
          >
            <Typography color="error.main" variant="body2">
              Error loading activity
            </Typography>
          </Box>
        ) : notifications.length > 0 ? (
          <List>
            {notifications.map((notification) => (
              <NotificationListItem
                notification={notification}
                key={notification.id}
              />
            ))}
          </List>
        ) : (
          <ZeroState />
        )
      }
    </Box>
  );
};

export default MemberActivity;
