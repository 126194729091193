import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import getQueryKeys from "@core/services/nocd-api/clinician-dashboard/query-keys";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

interface CreateNotoClinicianFeedback {
  clinician_email: string;
  feedback: string;
  feedback_details?: string | null;
}

const createNotoClinicianFeedback = (
  payload: CreateNotoClinicianFeedback,
  accessToken: string
) =>
  api
    .post<CreateNotoClinicianFeedback>(
      `/v1/clinicians/noto_feedback`,
      payload,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;

      return data;
    })
    .catch(transformAPIError);

export const useCreateNotoClinicianFeedback = (): UseMutationResult<
  CreateNotoClinicianFeedback,
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateNotoClinicianFeedback) => {
      return createNotoClinicianFeedback(payload, accessToken);
    },
    {
      onSuccess: (_, payload) => {
        return queryClient.invalidateQueries(
          getQueryKeys(accessToken).notoFeedbackForm(payload.clinician_email)
        );
      },
    }
  );
};
