import { Box } from "@mui/material";

const COLORS = {
  red: "nocdRed.main",
  blue: "nocdBlue.main",
};

export default function ItemCountBadge({
  count,
  color = "blue",
}: {
  count: number;
  color?: "red" | "blue";
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: COLORS[color],
        color: "white",
        fontSize: "12px",
        padding: "2px 8px",
        fontWeight: "700",
        borderRadius: "8px",
      }}
    >
      {count}
    </Box>
  );
}
