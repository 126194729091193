import { useCreateNotoClinicianFeedback } from "@features/clinician-dashboard/hooks/useCreateNotoFeedback";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

const FeedbackOptions = [
  "Very Satisfied",
  "Satisfied",
  "Neutral",
  "Dissatisfied",
  "Very Dissatisfied",
];

const useStyles = makeStyles((theme) => ({
  chipRoot: {
    margin: theme.spacing(0.5),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: 4,
  },
  chipLabel: {
    padding: theme.spacing(1.5),
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const ControlledChips = ({ control, name, error }) => {
  const classes = useStyles();
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            {FeedbackOptions.map((option) => (
              <Chip
                key={option}
                label={option}
                onClick={() => onChange(option)}
                className={
                  value === option
                    ? `${classes.chipRoot} ${classes.selectedChip}`
                    : classes.chipRoot
                }
                classes={{ label: classes.chipLabel }}
              />
            ))}
          </div>
        )}
      />
      {error?.[name]?.message ? (
        <Typography color="error" ml={1}>
          Rating required
        </Typography>
      ) : null}
    </>
  );
};
const schema = yup.object().shape({
  feedback: yup.string().required("Rating required"),
});

interface FormValues {
  feedback: string;
  feedback_details: string;
}

interface FeedbackProps {
  open: boolean;
  onClose: () => void;
  clinicianEmail: string;
}

const FeedbackDialog = ({ open, onClose, clinicianEmail }: FeedbackProps) => {
  const { handleSubmit, control, formState } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      feedback: "",
      feedback_details: null,
    },
  });
  const { errors, isSubmitting } = formState;
  const { mutateAsync: submitFeedback } = useCreateNotoClinicianFeedback();

  const onSubmit = (values) => {
    const payload = {
      clinician_email: clinicianEmail,
      feedback: values.feedback,
      feedback_details: values.feedback_details,
    };

    return toast.promise(submitFeedback(payload), {
      loading: "Saving your feedback...",
      success: "Your feedback has been saved, thank you",
      error: (err) => err.message,
    });
  };

  return (
    <form id="noto-feedback-form" onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>How satisfied are you with the NOTO?</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography
            gutterBottom
            color="textSecondary"
            sx={{ marginBottom: 2 }}
          >
            Thank you for sharing your experience. Your insights help us improve
            the tools and support we provide to our therapists, making NOCD even
            better for everyone who uses it.
          </Typography>
          <ControlledChips control={control} name="feedback" error={errors} />
          <Controller
            name="feedback_details"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  sx={{ marginTop: 3 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="comment"
                  label="Please tell us why and help us improve"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="dense"
                  {...field}
                  placeholder="Tell us a bit more"
                />
                <Typography variant="body2" color="textSecondary">
                  Your feedback is optional but highly appreciated.
                </Typography>
              </>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Box p={2}>
            <Typography variant="body2" color="textSecondary">
              Your feedback will be used to enhance the therapist experience at
              NOCD. We value your insights and value your feedback and
              appreciate your contributions. Please don&apos;t include any
              personal, sensitive, or confidential information in your feedback.
            </Typography>
          </Box>
          <Button onClick={onClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            color="primary"
            form="noto-feedback-form"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default FeedbackDialog;
