import { Box, BoxProps, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createContext, useContext, useMemo } from "react";

interface DescriptionListOptions {
  dense?: boolean;
  fallbackValue?: string;
  listItemClassName?: string;
}

const DescriptionListContext = createContext<DescriptionListOptions>({});

function useDescriptionListContext() {
  const context = useContext(DescriptionListContext);

  if (!context) {
    throw new Error(
      `Description list components must be wrapped in the DescriptionList component`
    );
  }
  return context;
}

interface DescriptionListProps extends BoxProps, DescriptionListOptions {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    backgroundColor: theme.palette.common.white,

    "&:nth-child(even)": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

function DescriptionList({
  children,
  dense = false,
  fallbackValue = "-",
  ...boxProps
}: DescriptionListProps) {
  const classes = useStyles();
  const listItemClassName = classes.listItem;

  const value = useMemo(
    () => ({ dense, fallbackValue, listItemClassName }),
    [dense, fallbackValue, listItemClassName]
  );

  return (
    <DescriptionListContext.Provider value={value}>
      <Box component={Paper} {...boxProps}>
        {children}
      </Box>
    </DescriptionListContext.Provider>
  );
}

interface DescriptionListHeaderProps {
  title: string;
  titleAction?: React.ReactNode;
  subheader?: React.ReactNode;
}

const DescriptionListHeader = ({
  title,
  titleAction,
  subheader,
}: DescriptionListHeaderProps) => {
  const { dense } = useDescriptionListContext();

  return (
    <Box px={dense ? 2 : 3} py={dense ? 1 : 2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="h3">
          {title}
        </Typography>

        {titleAction ?? null}
      </Box>
      {subheader ? <Typography variant="body2">{subheader}</Typography> : null}
    </Box>
  );
};

interface DescriptionListItemProps {
  label: React.ReactNode;
  value?: React.ReactNode;
  preserveWhiteSpace?: boolean;
}

const DescriptionListItem = ({
  label,
  value,
  preserveWhiteSpace,
}: DescriptionListItemProps) => {
  const { dense, fallbackValue, listItemClassName } =
    useDescriptionListContext();

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ md: "repeat(3,minmax(0,1fr))" }}
      gap="1rem"
      px={dense ? 2 : 3}
      py={dense ? 1 : 2}
      borderTop={1}
      borderColor="grey.300"
      className={listItemClassName}
    >
      <Box component="dt">
        <Typography variant="subtitle2">{label}</Typography>
      </Box>

      <Box component="dd" m={0} gridColumn={{ md: "span 2/span 2" }}>
        <Typography
          variant="body2"
          sx={
            preserveWhiteSpace
              ? {
                  whiteSpace: "pre-line",
                }
              : undefined
          }
        >
          {value ?? fallbackValue}
        </Typography>
      </Box>
    </Box>
  );
};

DescriptionList.Item = DescriptionListItem;
DescriptionList.Header = DescriptionListHeader;

export default DescriptionList;
