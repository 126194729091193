import { EhrRoles } from "@core/constants";
import { useUser } from "@core/services/nocd-api";
import Loading from "@core/ui/Loading";
import ClinicianDashboardV2 from "@features/clinician-dashboard/components/DashboardV2";
import {
  ArrowForward as ArrowForwardIcon,
  ArrowUpwardOutlined as ArrowUpwardIcon,
  Chat as ChatIcon,
  Dashboard as DashboardIcon,
  Event as EventIcon,
  ForumOutlined as ForumIcon,
  Group as GroupIcon,
  Help as HelpIcon,
  ListAlt as ListAltIcon,
  Phone as PhoneIcon,
  Share as ShareIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ViewList as ViewListIcon,
  Visibility as VisibilityIcon,
  WatchLater as WatchLaterIcon,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link as MUILink,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFlags } from "launchdarkly-react-client-sdk";
import Link from "next/link";
import { isChrome } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  email: {
    color: "#3c92dc",
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: "bold",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

interface FeatureCard {
  title: string;
  description: string;
  Icon: Element | JSX.Element;
  href: string;
  type: "internal" | "external";
}

const getCards = (
  isClinician: boolean,
  isIntake: boolean,
  isMemberAdvocate: boolean,
  isMemberAdvocateAuditor: boolean,
  associateProgramEnabled: boolean,
  canAccessCliniciansPage: boolean,
  canAccessAvailabilityRequestsPage: boolean,
  isClinicianSupervisor: boolean,
  isReferralsPageEnabled: boolean
) => {
  let cards: FeatureCard[] = [];

  if (canAccessCliniciansPage) {
    cards = [
      ...cards,
      {
        title: "Scheduling",
        description:
          "View/manage clinician schedules and their members' appointments",
        Icon: <SupervisorAccountIcon />,
        href: "/clinicians",
        type: "internal",
      },
      {
        title: "Members",
        description: "Search for a member and manage their schedule",
        Icon: <GroupIcon />,
        href: "/scheduling/members",
        type: "internal",
      },
      {
        title: "Clinicians",
        description: "View and filter a list of clinicians",
        Icon: <SupervisorAccountIcon />,
        href: "/clinicians/explore",
        type: "internal",
      },
    ];
  }

  if (canAccessAvailabilityRequestsPage) {
    cards = [
      ...cards,
      {
        title: "Availability Requests",
        description: "See changes clinicians have requested to their schedule",
        Icon: <ViewListIcon />,
        href: "/clinicians/availability-requests",
        type: "internal",
      },
    ];
  }

  if (isClinician) {
    cards = [
      ...cards,
      {
        title: "My Appointments",
        description:
          "View your scheduled, completed, and canceled appointments",
        Icon: <EventIcon />,
        href: "/appointments",
        type: "internal",
      },
      {
        title: "My Members",
        description: "View your members",
        Icon: <GroupIcon />,
        href: "/members",
        type: "internal",
      },
      {
        title: "Messaging",
        description: "Send messages to your members",
        Icon: <ChatIcon />,
        href: "/messaging",
        type: "internal",
      },
      {
        title: "My Availability",
        description: "Adjust your availability for therapy sessions",
        Icon: <WatchLaterIcon />,
        href: "/availability",
        type: "internal",
      },
      {
        title: "My Credentials",
        description: "View insurances you are credentialed in",
        Icon: <ListAltIcon />,
        href: "/credentials",
        type: "internal",
      },
      {
        title: "Knowledge Hub",
        description: "A hub for all NOCD therapist questions",
        Icon: <HelpIcon />,
        href: "https://sites.google.com/nocdhelp.com/knowledgehub/home",
        type: "external",
      },
      {
        title: "Therapist Referral Program",
        description: "Refer other therapists to NOCD",
        Icon: <ShareIcon />,
        href: "https://www.treatmyocd.com/therapist-referral-program",
        type: "external",
      },
      {
        title: "My Progress",
        description: `View your progress dashboards`,
        Icon: <DashboardIcon />,
        href: "/progress",
        type: "internal",
      },
      {
        title: "NOCD Community",
        description: "Interact with the NOCD community",
        Icon: <ForumIcon />,
        href: `${process.env.NEXT_PUBLIC_WEB_APP_BASE_URL}/login?redirectTo=/community`,
        type: "external",
      },
      associateProgramEnabled && isClinicianSupervisor
        ? {
            title: "Supervisor",
            description: "View Associates",
            Icon: <EventIcon />,
            href: `/supervisor?tab=${encodeURIComponent(
              "Associate Appointments"
            )}`,
            type: "internal",
          }
        : undefined,
      {
        title: "Move Up/Wait List Opportunities",
        description:
          "Request to take on members who are on the move up/wait list",
        Icon: <ArrowUpwardIcon />,
        href: "/moveups",
        type: "internal",
      },
    ].filter(Boolean) as FeatureCard[];
  }

  if (isIntake) {
    cards = [
      ...cards,
      {
        title: "Intake",
        description: "Manage scheduled and new calls for members joining NOCD",
        Icon: <PhoneIcon />,
        href: "/intake",
        type: "internal",
      },
      isReferralsPageEnabled && {
        title: "Inbound Referrals",
        description: "View Inbound Referrals",
        Icon: <EventIcon />,
        href: `/referrals?tab=${encodeURIComponent("Inbound Referrals")}`,
        type: "internal",
      },
    ].filter(Boolean) as FeatureCard[];
  }

  if (isMemberAdvocate) {
    cards = [
      ...cards,
      {
        title: "Member Advocate Messaging",
        description: "Send messages to members",
        Icon: <ChatIcon />,
        href: "/member-advocate-messaging",
        type: "internal",
      },
    ].filter(Boolean) as FeatureCard[];
  }

  if (isMemberAdvocateAuditor) {
    cards = [
      ...cards,
      {
        title: "Audit Advocate Messaging",
        description: "View any member advocate conversation",
        Icon: <VisibilityIcon />,
        href: "/audit-member-advocate-messaging",
        type: "internal",
      },
    ].filter(Boolean) as FeatureCard[];
  }

  return cards;
};

const SUPPORT_EMAIL = "support@nocdhelp.com";

function DefaultDashboard() {
  const classes = useStyles();
  const { associateProgramEnabled, enableReferralsPageInEhr } = useFlags();

  const { data: user } = useUser();
  const isClinician = user?.roles?.includes(EhrRoles.CLINICIAN);
  const isIntake = user?.roles?.includes(EhrRoles.INTAKE);
  const isMemberAdvocate = user?.roles?.includes(EhrRoles.MEMBER_ADVOCATE);
  const isMemberAdvocateAuditor = user?.roles?.includes(
    EhrRoles.MEMBER_ADVOCATE_AUDITOR
  );
  const isScheduler = user?.roles?.includes(EhrRoles.SCHEDULING);

  const isClinicianScheduleApprover = user?.roles?.includes(
    EhrRoles.CLINICIAN_SCHEDULE_APPROVER
  );

  const isClinicianSupervisor = user?.roles?.includes(
    EhrRoles.CLINICIAN_SUPERVISOR
  );

  const CARDS = getCards(
    isClinician,
    isIntake,
    isMemberAdvocate,
    isMemberAdvocateAuditor,
    Boolean(associateProgramEnabled),
    isScheduler,
    isClinicianScheduleApprover,
    isClinicianSupervisor,
    Boolean(enableReferralsPageInEhr)
  );

  return (
    <Box>
      {!isChrome && (
        <Alert severity="error" className={classes.alert}>
          <Typography>
            Your browser is not supported. Please download and use{" "}
            <MUILink href="https://www.google.com/chrome/">
              Google Chrome
            </MUILink>{" "}
            for the best experience.
          </Typography>
        </Alert>
      )}

      <Box mb={2}>
        <Typography className={classes.title} variant="h5">
          For help:
          <MUILink href={`mailto:${SUPPORT_EMAIL}`}>
            <span className={classes.email}>{SUPPORT_EMAIL}</span>
          </MUILink>
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {CARDS.map((card) => (
          <Grid item sm={6} xs={12} key={card.title}>
            <Card>
              <CardHeader
                disableTypography
                avatar={
                  <Avatar className={classes.avatar} aria-label={card.title}>
                    {card.Icon}
                  </Avatar>
                }
                title={<Typography variant="h6">{card.title}</Typography>}
              />

              <CardContent
                component={Grid}
                container
                direction="column"
                justifyContent="space-between"
              >
                <Typography>{card.description}</Typography>
              </CardContent>

              <CardActions disableSpacing>
                {card.type === "internal" && (
                  <Link href={card.href} passHref>
                    <Button
                      endIcon={<ArrowForwardIcon />}
                      color="primary"
                      component="a"
                      variant="text"
                    >
                      Launch
                    </Button>
                  </Link>
                )}

                {card.type === "external" && (
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={card.href}
                    style={{ textDecoration: "none" }}
                    color="primary"
                    component="a"
                    variant="text"
                  >
                    Launch
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default function IndexPage() {
  const { data: user } = useUser();
  if (!user) {
    return <Loading />;
  }

  const isClinician = user?.roles?.includes(EhrRoles.CLINICIAN);
  if (isClinician) {
    return <ClinicianDashboardV2 />;
  }

  return <DefaultDashboard />;
}

IndexPage.backgroundColor = "white";
