import { UtilizationOverview, WeekUtilization } from "@core/services/nocd-api";
import useUtilizationOverview from "@core/services/nocd-api/clinician-dashboard/queries/useUtilizationOverview";
import Loading from "@core/ui/Loading";
import removeDecimalsIfInt from "@core/utils/removeDecimalsIfInt";
import { Help } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format, formatISO, startOfQuarter } from "date-fns";

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    backgroundColor: theme.palette.common.white,
  },
  goalHitUtilizationCredit: {
    background: "#ffbcbf",
  },
  needUtilizationCredits: {
    background: "#bae4be",
  },
  today: {
    background: "#bbbcbc",
  },
  circle: {
    height: 20,
    width: 20,
    display: "inline-block",
    marginRight: theme.spacing(1),
    background: "#bbbcbc",
    borderRadius: 999,
    "&.redCircle": {
      background: "#ffbcbf",
    },
    "&.greenCircle": {
      background: "#bae4be",
    },
  },
  inputRoot: {
    "&$disabled": {
      color: "#606060",
    },
  },
  itemFlex: {
    display: "flex",
    alignItems: "center",
  },
}));

function Legend() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="left"
      spacing={1}
      padding={1}
    >
      <Grid item>
        <div className={classes.itemFlex}>
          <div className={`${classes.circle}`} />
          <Typography variant="body2" display="inline">
            means the current week that is still ongoing
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <div className={classes.itemFlex}>
          <div className={`${classes.circle} redCircle`} />
          <Typography variant="body2" display="inline">
            a completed week that was under your committed hours
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <div className={classes.itemFlex}>
          <div className={`${classes.circle} greenCircle`} />
          <Typography variant="body2" display="inline">
            a completed week that was greater than your committed hours
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

function getCurrentQuarter() {
  return formatISO(startOfQuarter(new Date()), { representation: "date" });
}

export function WeekUtilizationTable({
  utilizationData,
  isLoading,
  completedWeeks,
  completedUtilizationSum,
}: {
  utilizationData: UtilizationOverview;
  isLoading: boolean;
  completedWeeks: number;
  completedUtilizationSum: number;
}): JSX.Element {
  const classes = useStyles();

  const columns: GridColDef<WeekUtilization>[] = [
    {
      field: "week_name",
      headerName: "Current week",
      width: 110,
      type: "dateTime",
      valueFormatter: (params) => {
        if (params.value) {
          const valueAsDate = new Date(params.value as Date);
          return format(
            new Date(
              valueAsDate.getUTCFullYear(),
              valueAsDate.getUTCMonth(),
              valueAsDate.getUTCDate()
            ),
            "MMM d, yyyy"
          );
        }
        return undefined;
      },
    },
    {
      field: "utilization_credit",
      headerName: "Utilization hours",
      width: 90,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
      cellClassName: (params) => {
        const committedHours = Number(utilizationData?.committed_hours || 0);

        if (params.row.week_completed !== 1) {
          return params.row.current_week === 1 ? classes.today : null;
        }
        // Convert utilization_credit to a number to ensure correct comparison
        const utilizationCredit = Number(params.row.utilization_credit);

        return utilizationCredit >= committedHours
          ? classes.needUtilizationCredits
          : classes.goalHitUtilizationCredit;
      },
    },
    {
      field: "completed_hours",
      headerName: "Completed hours",
      width: 100,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
    },
    {
      field: "late_cancelled_hours",
      headerName: "Late cancelled hours",
      width: 100,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
    },
    {
      field: "pto_hours",
      headerName: "PTO hours",
      width: 100,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
    },
    {
      field: "first_sessions",
      headerName: "First sessions",
      width: 140,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
    },
    {
      field: "scheduled_hours",
      headerName: "Scheduled Hours",
      width: 140,
      valueFormatter: (params) => Math.round(params.value * 100) / 100,
    },
  ];

  return (
    <Box alignItems="center" padding={2} style={{ minHeight: 540 }}>
      <Alert
        sx={{ alignItems: "center" }}
        severity="info"
        iconMapping={{
          info: <Help />,
        }}
      >
        Have questions about utilization credit or PTO? Click
        <Button
          component="a"
          href="/utilization-dashboard"
          target="_blank"
          size="small"
          variant="text"
          sx={{
            mx: 1,
            backgroundColor: "#EDF5FE",
            border: "none",
            "&:hover": {
              backgroundColor: "#EDF5FE",
              border: "none",
            },
            color: "#3E7FDF",
            fontWeight: "bold",
          }}
        >
          View Utilization Details
        </Button>
        to see a weekly breakdowns, calculations, and more.
      </Alert>

      <Box
        alignItems="flex-start"
        padding={1}
        mt={1}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: "#ABAEB4",
            fontWeight: "700",
          }}
        >
          WEEKLY BREAKDOWN
        </Typography>
        <Button
          component="a"
          href="/utilization-dashboard"
          target="_blank"
          size="small"
          variant="outlined"
          sx={{
            backgroundColor: "#EDF5FE",
            border: "none",
            "&:hover": {
              backgroundColor: "#EDF5FE",
              border: "none",
            },
            color: "#3E7FDF",
            fontWeight: "bold",
          }}
        >
          View Utilization Details
        </Button>
      </Box>
      {isLoading ? (
        <Skeleton style={{ height: "300px" }} />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", xs: "10px" },
                  fontWeight: "900",
                  marginLeft: "10px",
                }}
              >
                Committed Hours:{" "}
                {removeDecimalsIfInt(+utilizationData?.committed_hours)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", xs: "10px" },
                  fontWeight: "900",
                  marginLeft: "10px",
                }}
              >
                Average Utilization of {removeDecimalsIfInt(completedWeeks)}{" "}
                adjusted hours over {completedUtilizationSum} weeks
              </Typography>
              <Grid
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Legend />
              </Grid>
            </Grid>
            <DataGridPro
              autoPageSize
              rowHeight={25}
              pagination
              pinnedColumns={{ left: ["week_name", "utilization_credit"] }}
              getRowId={(row) => row.week_name}
              className={classes.dataGrid}
              rows={(
                utilizationData?.clinician_quarter_utilization || []
              ).flat()}
              columns={columns}
              hideFooter
              style={{ height: "420px" }} // Default height before loading
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

function QuarterWeekUtilization({
  clinicianEmail,
}: {
  clinicianEmail: string;
}) {
  const selectedQuarter = getCurrentQuarter();
  const { data: quarterData, isLoading: isQuarterDataLoading } =
    useUtilizationOverview(clinicianEmail, selectedQuarter, null, null);

  if (!quarterData) {
    return (
      <Box
        alignItems="center"
        padding={2}
        style={{
          minHeight: 692,
          marginTop: "16px",
          border: "1px solid #CBD5E1",
          borderRadius: "8px",
        }}
      >
        <Loading />
      </Box>
    );
  }

  const completedWeeks =
    Math.round(quarterData?.completed_sum_utilization * 100) / 100 || 0;
  const completedUtilizationSum =
    Math.round(quarterData?.completed_weeks_total * 100) / 100 || 0;

  return (
    <Box
      component={Paper}
      paddingBottom={2}
      style={{
        marginTop: "16px",
        minHeight: 692,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
      }}
    >
      <WeekUtilizationTable
        completedUtilizationSum={completedUtilizationSum}
        completedWeeks={completedWeeks}
        utilizationData={quarterData}
        isLoading={isQuarterDataLoading}
      />
    </Box>
  );
}
export default QuarterWeekUtilization;
